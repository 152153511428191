<template>
  <v-container
    class="pa-5"
    fluid
    :style="{ maxWidth: '750px' }"
  >
    <v-card>
      <v-card-title>
        <h5
          class="ma-0"
        >
          Page Not Found
        </h5>
      </v-card-title>
      <v-divider/>
      <v-card-actions>
        <v-btn
          exact
          text
          :to="{ name: 'home' }"
        >
          Go to home.
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'NotFoundView',
};
</script>
