<template>
  <div>
    <v-dialog
      v-model="displayProxy"
      :content-class="contentClass"
      :fullscreen="fullscreen"
      :max-width="maxWidth"
      :no-click-animation="noClickAnimation"
      :persistent="persistent || loading"
    >
      <v-card
        class="base-dialog-card"
      >
        <v-form
          @submit.prevent="$emit('submit')"
        >
          <v-card-title
            class="no-transition-deep primary px-4 white--text"
          >
            <h5
              class="custom-font"
            >
              {{ title }}
            </h5>
            <v-spacer />
            <slot
              name="options"
            />
          </v-card-title>
          <v-card-text
            :class="noPadding ? 'pa-0' : 'pt-4 px-4'"
          >
            <div>
              <slot
                name="body"
              >
                {{ message }}
              </slot>
            </div>
          </v-card-text>
          <v-card-actions
            v-if="!hideActions"
            class="pb-4 px-4"
          >
            <v-spacer />
            <v-btn
              v-if="!hideActionButton"
              class="my-0"
              :color="actionColor"
              :disabled="actionDisabled"
              text
              type="submit"
            >
              {{ actionLabel }}
            </v-btn>
            <v-btn
              v-if="!hideCancelButton"
              class="my-0"
              color="secondary"
              text
              @click="displayProxy = false"
            >
              {{ cancelLabel }}
            </v-btn>
          </v-card-actions>
        </v-form>
        <Loader
          v-if="loading"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createProxy } from 'displet-vue';

import Loader from './Loader.vue';

export default {
  name: 'BaseDialog',

  components: {
    Loader,
  },

  props: {
    actionColor: {
      default: 'secondary',
      type: String,
    },
    actionDisabled: {
      default: false,
      type: Boolean,
    },
    actionLabel: {
      default: 'Submit',
      type: String,
    },
    cancelLabel: {
      default: 'Cancel',
      type: String,
    },
    contentClass: {
      default: null,
      type: String,
    },
    display: {
      required: true,
      type: Boolean,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    hideActionButton: {
      default: false,
      type: Boolean,
    },
    hideCancelButton: {
      default: false,
      type: Boolean,
    },
    hideActions: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    maxWidth: {
      default: 400,
      type: [Number, String],
    },
    message: {
      default: '',
      type: String,
    },
    noClickAnimation: {
      default: false,
      type: Boolean,
    },
    noPadding: {
      default: false,
      type: Boolean,
    },
    onClose: {
      default: null,
      type: Function,
    },
    onOpen: {
      default: null,
      type: Function,
    },
    persistent: {
      default: false,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
  },

  computed: {
    /** @type {boolean} */
    displayProxy: createProxy('display'),
  },

  watch: {
    async display(newValue) {
      if (!newValue) {
        if (typeof this.onClose === 'function') {
          this.onClose();
        }

        return;
      }

      if (typeof this.onOpen === 'function') {
        this.onOpen();
      }

      await this.$nextTick();
    },
  },
};
</script>
