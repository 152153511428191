import uuidValidate from 'uuid-validate';
import Vue from 'vue';
import * as rules from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { messages } from 'vee-validate/dist/locale/en.json';

Object.keys(rules)
  .forEach((rule) => {
    extend(rule, {
      ...rules[rule],
      message: messages[rule],
    });
  });

extend('lowercase', {
  message: 'The {_field_} field must be lowercase',
  validate: (value) => typeof value !== 'string'
    || value.toLowerCase() === value,
});

extend('uuid', {
  message: 'The {_field_} field must be a valid UUID',
  params: [
    'version',
  ],
  validate: (value, { version }) => {
    if (typeof value !== 'string') {
      return true;
    }

    return uuidValidate(value, version ? Number(version) : undefined);
  },
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
