<template>
  <v-container
    fluid
    :style="{ maxWidth: '750px' }"
  >
    <NetworkForm
      :item.sync="item"
    />
  </v-container>
</template>

<script>
import NetworkForm from '../components/NetworkForm.vue';

export default {
  name: 'NetworkCreateView',

  components: {
    NetworkForm,
  },

  data() {
    return {
      item: {},
    };
  },

  watch: {
    // eslint-disable-next-line object-shorthand
    'item.created_at'() {
      this.$router.push({
        name: 'network.edit',
        params: {
          networkId: this.item.uid,
        },
      });
    },
  },

  metaInfo: {
    title: 'Create Network',
  },
};
</script>
