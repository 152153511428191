<template>
  <v-container
    fluid
    :style="{ maxWidth: '750px' }"
  >
    <LoadingCard
      v-if="isLoading"
    />
    <NetworkForm
      :item.sync="item"
      v-else
    />
  </v-container>
</template>

<script>
import LoadingCard from '../components/LoadingCard.vue';
import NetworkForm from '../components/NetworkForm.vue';

export default {
  name: 'NetworkEditView',

  components: {
    LoadingCard,
    NetworkForm,
  },

  data() {
    return {
      isLoading: true,
      item: null,
    };
  },

  metaInfo: {
    title: 'Edit Network',
  },

  async created() {
    let uri = `/admin/networks/${this.$route.params.networkId}`;

    if (this.$route.query.is_deleted) {
      uri = `${uri}?is_deleted=1`;
    }

    try {
      const response = await this.$fetch(uri);

      if (response.status !== 200) {
        throw new Error('Unexpected status.');
      }

      this.item = await response.json();
      this.isLoading = false;
    } catch (e) {
      await this.$router.push({
        name: 'network.index',
      });
    }
  },
};
</script>
