<template>
  <div
    class="loader"
  >
    <v-container
      fill-height
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="text-center"
        >
          <v-progress-circular
            color="primary"
            indeterminate
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style>
.loader {
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
