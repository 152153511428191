<template>
  <BaseDialog
    :action-disabled="isActionDisabled"
    action-label="Select"
    :display.sync="displayProxy"
    max-width="500"
    title="Select File"
    @submit="handleSubmit()"
  >
    <template
      v-slot:body
    >
      <v-alert
        v-if="!isEmptyString(validateMessage)"
        color="red"
        text
      >
        {{ validateMessage }}
      </v-alert>
      <FileSelector
        :extensions="extensions"
        @change="handleChange($event)"
      />
    </template>
  </BaseDialog>
</template>

<script>
import { createProxy, isEmptyString } from 'displet-vue';

import BaseDialog from './BaseDialog.vue';
import FileSelector from './FileSelector.vue';

export default {
  name: 'FileSelectorDialog',

  components: {
    BaseDialog,
    FileSelector,
  },

  props: {
    display: {
      default: false,
      type: Boolean,
    },
    extensions: {
      default: () => ([]),
      type: Array,
    },
    validate: {
      default: null,
      type: Function,
    },
  },

  data() {
    return {
      file: null,
      validateMessage: null,
    };
  },

  computed: {
    /** @type {boolean} */
    displayProxy: createProxy('display'),

    /** @type {boolean} */
    isActionDisabled() {
      return !this.isEmptyString(this.validateMessage)
        || this.file === null;
    },
  },

  methods: {
    /** @return {void} */
    async handleChange(file) {
      this.file = file;
      this.validateMessage = null;

      if (typeof this.validate === 'function') {
        this.validateMessage = await this.validate(this.file);
      }
    },

    /** @return {void} */
    handleSubmit() {
      this.displayProxy = false;
      this.$emit('submit', this.file);
    },

    /** @return {string} */
    isEmptyString,
  },
};
</script>
