<template>
  <v-card>
    <v-card-text
      class="text-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'LoadingCard',
};
</script>
