import merge from 'lodash/merge';
import Vue from 'vue';

import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';

import './plugins/vee-validate';
import './plugins/vue-meta';

Vue.config.productionTip = false;

Vue.mixin({
  computed: {
    /** @type {boolean} */
    $isLoggedIn() {
      return !!this.$store.state.authToken;
    },
  },

  methods: {
    /** @return {any} */
    $env(key) {
      return process.env[key];
    },

    /** @return {Promise<Response>} */
    async $fetch(uri, data) {
      const defaultData = {};

      if (store.state.authToken) {
        defaultData.headers = {
          Authorization: store.state.authToken,
        };
      }

      const response = await fetch(`${this.$env('MYTRIBUS_API_URL')}${uri}`, merge(defaultData, data));

      if (
        store.state.authToken
        && response.status === 401
      ) {
        this.$store.commit('setAuthToken', null);
        await this.$router.push({ name: 'login' });
      }

      return response;
    },

    /** @return {void} */
    $resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
