<template>
  <v-container
    fluid
    :style="{ maxWidth: '750px' }"
  >
    <v-form
      @submit.prevent="handleLogin()"
    >
      <v-card
        :style="{ position: 'relative' }"
      >
        <v-card-title>
          <h5>
            Sign In
          </h5>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-alert
            :color="notification.color"
            dismissible
            text
            v-if="notification.display"
            v-model="notification.display"
          >
            {{ notification.message }}
          </v-alert>
          <v-text-field
            label="Email"
            v-model="data.email"
          />
          <v-text-field
            label="Password"
            type="password"
            v-model="data.password"
          />
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn
            color="secondary"
            type="submit"
          >
            Sign In
          </v-btn>
        </v-card-actions>
        <Loader
          v-if="isLoading"
        />
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import Loader from '../components/Loader.vue';

export default {
  name: 'LoginView',

  components: {
    Loader,
  },

  data() {
    return {
      data: {
        email: null,
        password: null,
      },
      isLoading: false,
      notification: {
        color: null,
        display: false,
        message: null,
      },
    };
  },

  metaInfo: {
    title: 'Sign In',
  },

  methods: {
    /** @return {Promise<void>} */
    async handleLogin() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      const response = await this.$fetch('/admin/tokens', {
        body: JSON.stringify(this.data),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      if (response.status === 200) {
        const { token } = await response.json();

        this.$store.commit('setAuthToken', token);
        await this.$router.push({ name: 'network.index' });
      } else if (response.status === 400) {
        this.setNotification('red', 'Invalid credentials.');
      } else {
        this.setNotification('red', 'Something unexpected occurred.');
      }

      this.isLoading = false;
    },

    /** @return {void} */
    setNotification(color, message) {
      this.notification.color = color;
      this.notification.message = message;
      this.notification.display = true;
    },
  },
};
</script>
