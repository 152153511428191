/** @return {string} */
function formatFileSize(bytes, si = true) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  let unitIndex = -1;
  let total = bytes;

  while (Math.abs(total) >= thresh && unitIndex < units.length - 1) {
    total /= thresh;
    unitIndex += 1;
  }

  return `${total.toFixed(1)} ${units[unitIndex]}`;
}

export default formatFileSize;
