import createPersistedState from 'vuex-persistedstate';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authToken: null,
  },

  mutations: {
    /** @return {void} */
    setAuthToken(state, newValue) {
      state.authToken = newValue;
    },
  },

  actions: {},

  modules: {},

  plugins: [
    createPersistedState(),
  ],
});
