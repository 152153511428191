<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row
      align="center"
    >
      <v-col
        class="pa-0 text-center"
      >
        <v-btn
          @click="$emit('input', currentPage - 1)"
          :disabled="currentPage === 1"
          class="ml-0"
          fab
          small
          text
        >
          <v-icon
            small
          >
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-select
          class="d-inline-block mx-2"
          :disabled="pageCount === 1"
          :items="pages"
          hide-details
          solo
          :style="{ maxWidth: '100px' }"
          v-model="currentPage"
        />
        <v-btn
          @click="$emit('input', currentPage + 1)"
          :disabled="currentPage === pageCount"
          class="mr-0"
          fab
          small
          text
        >
          <v-icon
            small
          >
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import range from 'lodash/range';

export default {
  name: 'PageSelect',

  props: {
    page: {
      required: true,
      type: [
        Number,
        String,
      ],
    },
    perPage: {
      default: null,
      type: Number,
    },
    total: {
      default: null,
      type: Number,
    },
  },

  computed: {
    /** @type {number} */
    currentPage: {
      get() {
        return Number(this.page);
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },

    /** @type {number} */
    pageCount() {
      return Math.ceil(this.total / this.perPage) || 1;
    },

    /** @type {array} */
    pages() {
      return range(1, this.pageCount + 1);
    },
  },
};
</script>
