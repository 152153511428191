<template>
  <div>
    <v-container
      class="pa-5"
      fluid
      :style="{ maxWidth: '1500px' }"
    >
      <v-card>
        <v-card-title>
          <v-layout>
            <v-row>
              <v-col
                class="py-0"
              >
                <h5>
                  Networks
                  <small>
                    {{ total }}
                  </small>
                </h5>
              </v-col>
              <v-col
                class="py-0 text-right"
              >
                <v-btn
                  color="secondary"
                  :to="{ name: 'network.create' }"
                >
                  Create
                </v-btn>
              </v-col>
            </v-row>
          </v-layout>
        </v-card-title>
        <v-divider/>
        <v-data-table
          :headers="headers"
          hide-default-footer
          :items="items"
          :items-per-page="queryParams.per_page"
          :loading="isLoading"
        >
          <template #item.uid="{ item }">
            <router-link
              :to="getEditUrl(item)"
            >
              {{ item.uid }}
            </router-link>
          </template>
          <template #item.subdomain="{ item }">
            <a
              :href="`http://${item.subdomain}.${item.customer_type.domain}`"
              rel="noreferrer noopener"
              target="_blank"
            >
              {{ item.subdomain }}
            </a>
          </template>
          <template #item.file_size_total="{ item }">
            <v-tooltip
              top
            >
              <template
                v-slot:activator="{ on }"
              >
                <span
                  v-on="on"
                >
                  {{ formatFileSize(item.file_size_total) }}
                </span>
              </template>
              <span>
                {{ item.file_size_total }}
              </span>
            </v-tooltip>
          </template>
          <template #item.created_at="{ item }">
            {{ formatDateTime(item.created_at) }}
          </template>
          <template #item.deleted_at="{ item }">
            {{ formatDateTime(item.deleted_at) }}
          </template>
          <template #item.updated_at="{ item }">
            {{ formatDateTime(item.updated_at) }}
          </template>
        </v-data-table>
        <template
          v-if="total > queryParams.per_page"
        >
          <v-divider/>
          <v-card-text>
            <div>
              <PageSelect
                @input="handleFilterInput('page', $event)"
                :page="queryParams.page"
                :per-page="queryParams.per_page"
                :total="total"
              />
            </div>
          </v-card-text>
        </template>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import qs from 'query-string';

import formatDateTime from '../formatters/formatDateTime';
import formatFileSize from '../formatters/formatFileSize';
import PageSelect from '../components/PageSelect.vue';

export default {
  name: 'NetworkIndexView',

  components: {
    PageSelect,
  },

  data() {
    return {
      baseParams: {
        page: 1,
        per_page: 100,
        sort_by: 'subdomain',
        sort_order: 'asc',
      },
      isLoading: true,
      items: [],
      total: 0,
    };
  },

  computed: {
    /** @return {object} */
    headers() {
      return [
        {
          sortable: false,
          text: 'UID',
          value: 'uid',
        },
        {
          sortable: false,
          text: 'Subdomain',
          value: 'subdomain',
        },
        {
          sortable: false,
          text: 'Coach',
          value: 'has_coach',
        },
        {
          sortable: false,
          text: 'Property Marketing',
          value: 'has_property_marketing',
        },
        {
          sortable: false,
          text: 'Premium CRM',
          value: 'has_premium_crm',
        },
        {
          sortable: false,
          text: 'Support',
          value: 'has_support',
        },
        {
          sortable: false,
          text: 'Websites',
          value: 'has_websites',
        },
        {
          sortable: false,
          text: 'Total Storage',
          value: 'file_size_total',
        },
        {
          sortable: false,
          text: 'Created',
          value: 'created_at',
        },
        {
          sortable: false,
          text: this.$route.query.is_deleted ? 'Deleted' : 'Updated',
          value: this.$route.query.is_deleted ? 'deleted_at' : 'updated_at',
        },
      ];
    },

    /** @type {object} */
    queryParams() {
      return {
        ...this.baseParams,
        ...this.$route.query,
      };
    },
  },

  watch: {
    // eslint-disable-next-line object-shorthand
    async '$route.query'() {
      await this.loadItems();
    },
  },

  metaInfo: {
    title: 'Networks',
  },

  async created() {
    await this.loadItems();
  },

  methods: {
    /** @return {string} */
    formatDateTime,

    /** @return {string} */
    formatFileSize,

    /** @return {object} */
    getEditUrl(item) {
      const to = {
        name: 'network.edit',
        params: {
          networkId: item.uid,
        },
      };

      if (this.$route.query.is_deleted) {
        to.query = {
          is_deleted: 1,
        };
      }

      return to;
    },

    /** @return {Promise<void>} */
    async handleFilterInput(key, value) {
      const query = {
        ...this.$route.query,
        [key]: value,
      };

      if (value === '') {
        delete query[key];
      }

      if (
        key !== 'page'
        && typeof query.page !== 'undefined'
      ) {
        delete query.page;
      }

      try {
        await this.$router.push({
          name: this.$route.name,
          query,
        });
      } catch (e) {
        //
      }
    },

    /** @return {Promise<void>} */
    async loadItems() {
      this.isLoading = true;

      const response = await this.$fetch(`/admin/networks?${qs.stringify(this.queryParams)}`);

      const body = await response.json();

      this.items = body.results;
      this.total = body.total;

      this.isLoading = false;
    },
  },
};
</script>
