import asyncPool from 'tiny-async-pool';

/** @return {Promise<array>} */
async function asyncPoolAll(...args) {
  const results = [];

  /* eslint-disable-next-line no-restricted-syntax  */
  for await (const result of asyncPool(...args)) {
    results.push(result);
  }

  return results;
}

export default asyncPoolAll;
