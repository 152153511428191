import { DateTime } from 'luxon';

function formatDateTime(value) {
  if (!value) {
    return '';
  }

  return DateTime.fromISO(value).toFormat('L/d/yyyy h:mm a');
}

export default formatDateTime;
