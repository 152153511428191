import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

import FormatContactImportToolView from '../views/FormatContactImportToolView.vue';
import LoginView from '../views/LoginView.vue';
import NetworkCreateView from '../views/NetworkCreateView.vue';
import NetworkEditView from '../views/NetworkEditView.vue';
import NetworkIndexView from '../views/NetworkIndexView.vue';
import NotFoundView from '../views/NotFoundView.vue';

Vue.use(VueRouter);

const redirects = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'network.index',
    },
  },
];

const routes = [
  {
    path: '/login',
    meta: {
      guest: true,
    },
    name: 'login',
    component: LoginView,
  },
  {
    path: '/networks',
    name: 'network.index',
    component: NetworkIndexView,
  },
  {
    path: '/networks/:networkId/edit',
    name: 'network.edit',
    component: NetworkEditView,
  },
  {
    path: '/networks/create',
    name: 'network.create',
    component: NetworkCreateView,
  },
  {
    path: '/tools/format-contact-import',
    name: 'tools.formatContactImport',
    component: FormatContactImportToolView,
  },
  {
    path: '*',
    name: '404',
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...redirects,
    ...routes,
  ],
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = !!store.state.authToken;

  if (isAuthenticated && to.meta.guest) {
    next({ name: 'network.index' });
    return;
  }

  if (!isAuthenticated && !to.meta.guest) {
    next({ name: 'login' });
    return;
  }

  next();
});

export default router;
