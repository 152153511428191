var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-5",style:({ maxWidth: '1500px' }),attrs:{"id":"format-contact-import-tool-view","fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"py-1"},[_c('v-layout',[_c('v-row',[_c('v-col',{staticClass:"flex-grow-0 text-no-wrap",attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : false}},[_c('h5',[_vm._v(" Contacts "),_c('small',[_vm._v(" "+_vm._s(_vm.rows.length)+" ")])])]),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : false}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"append-icon":"mdi-magnify","disabled":!_vm.hasRows,"label":"Search","hide-details":"","single-line":""},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c('v-col',{class:['flex-grow-0', { 'text-no-wrap': !_vm.$vuetify.breakpoint.mobile }],attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12' : false}},_vm._l((_vm.tableActions),function(action,index){return _c('span',{key:index},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.isEmptyString(action.disabledToolTip) || !_vm.hasRows},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{class:[{
                        'ml-2': index !== 0 && !_vm.$vuetify.breakpoint.mobile,
                        'mt-2': index !== 0 && _vm.$vuetify.breakpoint.mobile,
                      }],attrs:{"block":_vm.$vuetify.breakpoint.mobile,"color":"secondary","disabled":action.disabled},on:{"click":function($event){return action.onClick()}}},[(action.icon)?_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(action.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(action.label)+" ")],1)],1)]}}],null,true)},[_vm._v(" "+_vm._s(action.disabledToolTip)+" ")])],1)}),0)],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"fixed-header":"","headers":_vm.currentHeaders,"hide-default-header":"","items":_vm.rows,"loading":_vm.isLoading,"no-data-text":_vm.noDataText,"search":_vm.searchInput},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                      var props = ref.props;
return [_c('thead',_vm._l((props.headers),function(currentHeader,index){return _c('th',{key:index,staticClass:"px-4",attrs:{"scope":"col"}},[_c('v-select',{staticClass:"my-2 required",style:({ minWidth: '175px' }),attrs:{"dense":"","full-width":"","hide-details":"","items":_vm.allHeaders,"label":"Header","outlined":"","return-object":"","single-line":"","value":currentHeader},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openConfirmRemoveColumnDialog(currentHeader, index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" close ")])],1)],1)]}}],null,true)},[_vm._v(" Remove Column ")])]},proxy:true},{key:"item",fn:function(ref){
                      var item = ref.item;
                      var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"disabled":_vm.isHeaderSelected(item)},on:{"click":function($event){return _vm.handleHeaderChange(item, currentHeader)}}},on),[_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.isHeaderSelected(item) ? 'grey--text' : ''},[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)]}}],null,true)})],1)}),0)]}},{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('tr',_vm._l((Object.keys(item)),function(key,index){return _c('td',{key:index},[_vm._v(" "+_vm._s(item[key])+" ")])}),0)]}}])})],1),(_vm.dialogs.confirmRemoveColumn.display)?_c('BaseDialog',{attrs:{"action-color":"red","action-label":"Confirm","display":_vm.dialogs.confirmRemoveColumn.display,"title":"Remove Column"},on:{"update:display":function($event){return _vm.$set(_vm.dialogs.confirmRemoveColumn, "display", $event)},"submit":function($event){return _vm.removeColumn()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" Are you sure that you want to remove \""+_vm._s(_vm.dialogs.confirmRemoveColumn.column.value)+"\"? ")]},proxy:true}],null,false,3528588874)}):_vm._e(),_c('FileSelectorDialog',{attrs:{"display":_vm.dialogs.fileSelector.display,"extensions":['csv'],"validate":_vm.validateFile},on:{"update:display":function($event){return _vm.$set(_vm.dialogs.fileSelector, "display", $event)},"submit":function($event){return _vm.handleSelectedFile($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }