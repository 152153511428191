<template>
  <v-container
    class="pa-5"
    id="format-contact-import-tool-view"
    fluid
    :style="{ maxWidth: '1500px' }"
  >
    <v-card>
      <v-card-title
        class="py-1"
      >
        <v-layout>
          <v-row>
            <v-col
              class="flex-grow-0 text-no-wrap"
              :cols="$vuetify.breakpoint.mobile ? '12' : false"
            >
              <h5>
                Contacts
                <small>
                  {{ rows.length }}
                </small>
              </h5>
            </v-col>
            <v-col
              class=""
              :cols="$vuetify.breakpoint.mobile ? '12' : false"
            >
              <v-text-field
                v-model="searchInput"
                append-icon="mdi-magnify"
                :disabled="!hasRows"
                class="ma-0 pa-0"
                label="Search"
                hide-details
                single-line
              />
            </v-col>
            <v-col
              :class="['flex-grow-0', { 'text-no-wrap': !$vuetify.breakpoint.mobile }]"
              :cols="$vuetify.breakpoint.mobile ? '12' : false"
            >
              <span
                v-for="(action, index) in tableActions"
                :key="index"
              >
                <v-tooltip
                  bottom
                  :disabled="isEmptyString(action.disabledToolTip) || !hasRows"
                >
                  <template
                    v-slot:activator="{ on }"
                  >
                    <span
                      v-on="on"
                    >
                      <v-btn
                        :block="$vuetify.breakpoint.mobile"
                        :class="[{
                          'ml-2': index !== 0 && !$vuetify.breakpoint.mobile,
                          'mt-2': index !== 0 && $vuetify.breakpoint.mobile,
                        }]"
                        color="secondary"
                        :disabled="action.disabled"
                        @click="action.onClick()"
                      >
                        <v-icon
                          v-if="action.icon"
                          class="mr-2"
                        >
                          {{ action.icon }}
                        </v-icon>
                        {{ action.label }}
                      </v-btn>
                    </span>
                  </template>
                  {{ action.disabledToolTip }}
                </v-tooltip>
              </span>
            </v-col>
          </v-row>
        </v-layout>
      </v-card-title>
      <v-divider/>
      <v-data-table
        fixed-header
        :headers="currentHeaders"
        hide-default-header
        :items="rows"
        :loading="isLoading"
        :no-data-text="noDataText"
        :search="searchInput"
      >
        <template
          v-slot:header="{ props }"
        >
          <thead>
            <th
              v-for="(currentHeader, index) in props.headers"
              :key="index"
              class="px-4"
              scope="col"
            >
              <v-select
                class="my-2 required"
                dense
                full-width
                hide-details
                :items="allHeaders"
                label="Header"
                outlined
                return-object
                single-line
                :style="{ minWidth: '175px' }"
                :value="currentHeader"
              >
                <template
                  v-slot:selection="{ item }"
                >
                  <span
                    class="text-no-wrap"
                  >
                    {{ item.text }}
                  </span>
                </template>
                <template
                  v-slot:append-outer
                >
                  <v-tooltip
                    top
                  >
                    <template
                      v-slot:activator="{ on }"
                    >
                      <span
                        v-on="on"
                      >
                        <v-btn
                          icon
                          small
                          @click="openConfirmRemoveColumnDialog(currentHeader, index)"
                        >
                          <v-icon
                            small
                          >
                            close
                          </v-icon>
                        </v-btn>
                      </span>
                    </template>
                    Remove Column
                  </v-tooltip>
                </template>
                <template
                  v-slot:item="{item, on }"
                >
                  <v-list-item
                    v-on="on"
                    @click="handleHeaderChange(item, currentHeader)"
                    :disabled="isHeaderSelected(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        :class="isHeaderSelected(item) ? 'grey--text' : ''"
                      >
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </th>
          </thead>
        </template>
        <template
          v-slot:item="{ item }"
        >
          <tr>
            <td
              v-for="(key, index) in Object.keys(item)"
              :key="index"
            >
              {{ item[key] }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <BaseDialog
      v-if="dialogs.confirmRemoveColumn.display"
      action-color="red"
      action-label="Confirm"
      :display.sync="dialogs.confirmRemoveColumn.display"
      title="Remove Column"
      @submit="removeColumn()"
    >
      <template
        v-slot:body
      >
        Are you sure that you want to remove "{{ dialogs.confirmRemoveColumn.column.value }}"?
      </template>
    </BaseDialog>
    <FileSelectorDialog
      :display.sync="dialogs.fileSelector.display"
      :extensions="['csv']"
      :validate="validateFile"
      @submit="handleSelectedFile($event)"
    />
  </v-container>
</template>

<script>
import moment from 'moment';
import Papa from 'papaparse';
import { cloneDeep } from 'lodash';
import { isEmptyString } from 'displet-vue';

import BaseDialog from '../components/BaseDialog.vue';
import FileSelectorDialog from '../components/FileSelectorDialog.vue';
import { IMPORT_COLUMNS } from '../config/contact';

export default {
  name: 'FormatContactImportToolView',

  components: {
    BaseDialog,
    FileSelectorDialog,
  },

  data() {
    return {
      allHeaders: [],
      currentHeaders: [],
      dialogs: {
        confirmRemoveColumn: {
          column: null,
          display: false,
          index: null,
        },
        fileSelector: {
          display: false,
        },
      },
      file: null,
      headerDefaults: {
        baseField: null,
        childField: null,
        index: null,
        isListable: false,
        isRepeatable: false,
        text: null,
        value: null,
      },
      isLoading: false,
      rows: [],
      sanitizeConfig: {
        formatDate: [
          'created_at',
          'deleted_at',
          'important_dates\\[\\d]\\[date]',
          'last_contacted_at',
        ],
        formatNumber: [
          'phone_numbers\\[\\d]\\[number]',
        ],
        trim: [
          'additional_email_addresses\\[\\d]\\[email]',
          'email',
        ],
      },
      searchInput: '',
    };
  },

  computed: {
    /** @type {boolean} */
    areAllCurrentHeadersSelected() {
      const allHeaderValues = this.allHeaders.map((header) => header.value);

      return this.currentHeaders
        .every((header) => allHeaderValues.includes(header.value));
    },

    /** @type {boolean} */
    hasRows() {
      return this.rows.length > 0;
    },

    /** @type {string} */
    noDataText() {
      if (this.isLoading) {
        return 'Loading..';
      }

      return this.file === null
        ? 'Please select a file.'
        : 'No data available.';
    },

    /** @type {array} */
    tableActions() {
      return [
        {
          disabled: false,
          icon: 'file_upload',
          label: `${this.file === null ? 'Select' : 'Change'} File`,
          onClick: () => {
            this.dialogs.fileSelector.display = true;
          },
        },
        {
          disabled: !this.hasRows || !this.areAllCurrentHeadersSelected,
          disabledToolTip: 'Please ensure that all headers are selected.',
          icon: 'file_download',
          label: 'Download File',
          onClick: this.downloadFile,
        },
      ];
    },
  },

  metaInfo: {
    title: 'Format Contact Import - Tools',
  },

  created() {
    IMPORT_COLUMNS.singles.forEach((column) => {
      this.addToAllHeaders(column);
    });

    IMPORT_COLUMNS.listables.forEach((column) => {
      this.addToAllHeaders(column, 0);
    });

    IMPORT_COLUMNS.repeatables.forEach((column) => {
      column.fields.forEach((field) => {
        this.addToAllHeaders(column.key, 0, field);
      });
    });
  },

  methods: {
    /** @return {void} */
    addToAllHeaders(baseField, index = null, childField = null) {
      let value = baseField;

      if (index !== null) {
        value = `${value}[${index}]`;
      }

      if (childField !== null) {
        value = `${value}[${childField}]`;
      }

      this.allHeaders.push({
        ...this.headerDefaults,
        baseField,
        childField,
        index,
        isListable: index !== null && childField === null,
        isRepeatable: index !== null && childField !== null,
        text: value,
        value,
      });

      this.allHeaders.sort((a, b) => (a.text > b.text ? 1 : -1));
    },

    /** @return {void} */
    downloadFile() {
      const clonedRows = cloneDeep(this.rows);

      clonedRows.forEach((row) => {
        Object.keys(row).forEach((key) => {
          Object.keys(this.sanitizeConfig).forEach((configKey) => {
            if (this.sanitizeConfig[configKey].some((field) => new RegExp(`^${field}$`).test(key))) {
              // eslint-disable-next-line no-param-reassign
              row[key] = this.sanitize(configKey, row[key]);
            }
          });
        });
      });

      const csvBody = Papa.unparse([
        this.currentHeaders.map((header) => header.value),
        ...clonedRows.map((item) => Object.values(item)),
      ]);

      const csvData = new Blob([csvBody], {
        type: 'text/csv;charset=utf-8;',
      });

      const fileName = `${this.file.name}_formatted.csv`;

      const csvUrl = navigator.msSaveBlob
        ? navigator.msSaveBlob(csvData, fileName)
        : window.URL.createObjectURL(csvData);

      const downloadLink = document.createElement('a');

      downloadLink.setAttribute('href', csvUrl);
      downloadLink.setAttribute('download', fileName);
      downloadLink.click();
    },

    /** @return {void} */
    handleHeaderChange(selectedHeader, currentHeader) {
      if (selectedHeader.isListable) {
        const newIndex = selectedHeader.index + 1;
        this.addToAllHeaders(selectedHeader.baseField, newIndex);
      }

      if (selectedHeader.isRepeatable) {
        const column = IMPORT_COLUMNS
          .repeatables
          .find((item) => item.key === selectedHeader.baseField);

        const newIndex = selectedHeader.index + 1;

        column.fields.forEach((field) => {
          this.addToAllHeaders(selectedHeader.baseField, newIndex, field);
        });
      }

      this.rows = this.rows.map((item) => {
        let clonedItem = cloneDeep(item);

        const keyValue = clonedItem[currentHeader.value];
        const keyIndex = Object.keys(clonedItem).indexOf(currentHeader.value);

        delete clonedItem[currentHeader.value];

        clonedItem = Object.entries(clonedItem);
        clonedItem.splice(keyIndex, 0, [selectedHeader.value, keyValue]);

        return Object.fromEntries(clonedItem);
      });

      this.maybeUpdateAllHeaders(currentHeader);

      Object.assign(currentHeader, {
        ...selectedHeader,
      });

      this.allHeaders.sort((a, b) => (a.text > b.text ? 1 : -1));
    },

    /** @return {void} */
    async handleSelectedFile(file) {
      if (file === null) {
        return;
      }

      this.isLoading = true;
      this.file = file;
      this.rows = [];

      const fileContents = await this.file.text();
      const [headers, ...rows] = Papa.parse(fileContents).data;

      headers.forEach((item) => {
        const formattedHeader = item.toLowerCase().trim().replace(' ', '_');
        const foundHeader = this.allHeaders.find((header) => header.value === formattedHeader);

        if (foundHeader !== undefined) {
          this.currentHeaders.push(foundHeader);
        } else {
          this.currentHeaders.push({
            ...this.headerDefaults,
            text: formattedHeader,
            value: formattedHeader,
          });
        }
      });

      const keys = this.currentHeaders.map((header) => header.value);

      rows.forEach((row) => {
        const item = {};

        keys.forEach((key, index) => {
          item[key] = row[index];
        });

        this.rows.push(item);
      });

      this.isLoading = false;
    },

    /** @return {boolean} */
    isEmptyString,

    /** @return {boolean} */
    isHeaderSelected(header) {
      return this.currentHeaders.map((currentHeader) => currentHeader.value).includes(header.value);
    },

    /** @return {void} */
    maybeUpdateAllHeaders(oldHeader) {
      if (
        !oldHeader.isRepeatable
        && !oldHeader.isListable
      ) {
        return;
      }

      const relatedHeadersSortedByIndex = this.currentHeaders
        .filter((currentHeader) => currentHeader.baseField === oldHeader.baseField)
        .sort((a, b) => (a.index < b.index ? 1 : -1));

      const highestRepeatableIndex = relatedHeadersSortedByIndex.length > 0
        ? relatedHeadersSortedByIndex[0].index + 1
        : 0;

      this.allHeaders = this.allHeaders
        .filter((allHeader) => (
          allHeader.baseField !== oldHeader.baseField
            || allHeader.index <= highestRepeatableIndex
        ));
    },

    /** @return {void} */
    openConfirmRemoveColumnDialog(column, index) {
      const { confirmRemoveColumn } = this.dialogs;

      confirmRemoveColumn.column = column;
      confirmRemoveColumn.index = index;
      confirmRemoveColumn.display = true;
    },

    /** @return {void} */
    removeColumn() {
      const { confirmRemoveColumn } = this.dialogs;

      if (this.currentHeaders.length <= 1) {
        this.$resetData();
        return;
      }

      this.currentHeaders.splice(confirmRemoveColumn.index, 1);

      this.rows.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        delete item[confirmRemoveColumn.column.value];
      });

      this.maybeUpdateAllHeaders(confirmRemoveColumn.column);
      confirmRemoveColumn.display = false;
    },

    /** @return {string} */
    sanitize(action, value) {
      switch (action) {
        case 'formatDate': {
          return moment.utc(value).toISOString();
        }

        case 'formatNumber': {
          return value.replace(/[^0-9.]/g, '');
        }

        case 'trim': {
          return value.trim();
        }

        default: {
          throw new Error('Please provide a known "action".');
        }
      }
    },

    /** @return {null|string} */
    async validateFile(file) {
      const fileContents = await file.text();
      const [headers, ...rows] = Papa.parse(fileContents).data;

      if (rows.length === 0) {
        return 'Your CSV must have rows of data.';
      }

      if (rows.find((row) => row.length !== headers.length)) {
        return 'Your CSV must have a consistent number of columns.';
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
#format-contact-import-tool-view {
  table {
    td, th {
      &:nth-of-type(even) {
        background-color: rgba(0, 0, 0, .03) !important;
      }
    }
  }
}
</style>
