/* eslint-disable import/prefer-default-export */
export const IMPORT_COLUMNS = {
  listables: [
    'notes',
    'tags',
    'tag_ids',
  ],
  repeatables: [
    {
      fields: [
        'primary',
        'steet_address',
        'street_address_2',
        'city',
        'state',
        'zip',
        'county',
      ],
      key: 'addresses',
    },
    {
      fields: [
        'email',
        'type',
      ],
      key: 'additional_email_addresses',
    },
    {
      fields: [
        'type',
        'date',
      ],
      key: 'important_dates',
    },
    {
      fields: [
        'extension',
        'number',
        'primary',
        'type',
      ],
      key: 'phone_numbers',
    },
    {
      fields: [
        'primary',
        'thumbnail_url',
        'url',
        'type',
      ],
      key: 'photos',
    },
    {
      fields: [
        'name',
        'relation',
      ],
      key: 'relatives',
    },
    {
      fields: [
        'type',
        'url',
      ],
      key: 'urls',
    },
  ],
  singles: [
    'channel',
    'company',
    'company_generated',
    'created_at',
    'credit_range',
    'display_name',
    'email',
    'first_name',
    'global_unsubscribed',
    'id',
    'last_contacted_at',
    'last_name',
    'login',
    'miscellany',
    'military',
    'password',
    'rating',
    'referral_fee_applies',
    'referred_by',
    'registration_property_id',
    'relocation',
    'source',
    'status',
    'title',
    'user_id',
    'vow_approved',
  ],
};
