var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{style:({ position: 'relative' })},[_c('v-card-title',[_c('h5',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm.exists ? 'Edit' : 'Create')+" Network ")])]),(_vm.exists)?[_c('v-divider'),_c('v-list',{staticClass:"body-2",attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" Client ")]),_c('v-list-item-content',[_c('div',{staticClass:"text-right"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.myTribusClientUrl,"rel":"noreferrer noopener","target":"_blank"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" launch ")])],1)])])],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v(" Created ")]),_c('v-list-item-content',[_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.item.created_at))+" ")])])],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.item.deleted_at ? 'Deleted' : 'Updated')+" ")]),_c('v-list-item-content',[_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.item.deleted_at || _vm.item.updated_at))+" ")])])],1)],1)]:_vm._e(),_c('v-divider'),_c('v-card-text',[(_vm.notification.display)?_c('v-alert',{attrs:{"color":_vm.notification.color,"dismissible":"","text":""},model:{value:(_vm.notification.display),callback:function ($$v) {_vm.$set(_vm.notification, "display", $$v)},expression:"notification.display"}},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"UID","rules":"required|uuid:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required",attrs:{"error-messages":errors,"disabled":_vm.exists || _vm.isDisabled,"label":"UID"},model:{value:(_vm.item.uid),callback:function ($$v) {_vm.$set(_vm.item, "uid", $$v)},expression:"item.uid"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"customer type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"required",attrs:{"error-messages":errors,"item-text":"label","items":_vm.customerTypes.items,"loading":_vm.customerTypes.isLoading,"label":"Customer Type","return-object":""},model:{value:(_vm.item.customer_type),callback:function ($$v) {_vm.$set(_vm.item, "customer_type", $$v)},expression:"item.customer_type"}})]}}],null,true)}),(!_vm.customerTypes.isLoading && _vm.item.customer_type.domain !== undefined)?[_c('ValidationProvider',{attrs:{"name":"subdomain","rules":"required|alpha_num|max:32|lowercase"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"required mb-2",attrs:{"disabled":_vm.isDisabled,"error-messages":errors,"label":"Subdomain","loading":_vm.customerTypes.isLoading,"suffix":("." + (_vm.item.customer_type.domain))},model:{value:(_vm.item.subdomain),callback:function ($$v) {_vm.$set(_vm.item, "subdomain", $$v)},expression:"item.subdomain"}})]}}],null,true)}),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_c('h6',[_vm._v(" Feature Flags ")])]),_c('v-divider'),_c('div',[(_vm.featureFlags.isLoading)?_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1):(_vm.featureFlags.failedToLoad)?_c('v-card-text',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" warning ")]),_vm._v(" Something unexpected occurred while loading feature flags. ")],1):[_c('v-data-table',{attrs:{"headers":_vm.featureFlags.headers,"hide-default-footer":"","items":Object.keys(_vm.config.FEATURE_FLAGS),"items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var key = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.config.FEATURE_FLAGS[key].label)+" ")]),_c('td',[_c('v-checkbox',{staticClass:"ma-0",attrs:{"color":"secondary","disabled":_vm.isDisabled,"hide-details":"","input-value":_vm.featureFlags.pendingValues[key] === 'allow',"readonly":_vm.featureFlags.pendingValues[key] === 'allow'},on:{"change":function($event){_vm.featureFlags.pendingValues[key] = 'allow'}}})],1),_c('td',[_c('v-checkbox',{staticClass:"ma-0",attrs:{"color":"secondary","disabled":_vm.isDisabled,"hide-details":"","input-value":_vm.featureFlags.pendingValues[key] === 'deny',"readonly":_vm.featureFlags.pendingValues[key] === 'deny'},on:{"change":function($event){_vm.featureFlags.pendingValues[key] = 'deny'}}})],1),_c('td',[_c('v-checkbox',{staticClass:"ma-0",attrs:{"color":"secondary","disabled":_vm.isDisabled,"hide-details":"","input-value":_vm.featureFlags.pendingValues[key] === 'inherit',"label":_vm.getDefaultFeatureLabel(key),"readonly":_vm.featureFlags.pendingValues[key] === 'inherit'},on:{"change":function($event){_vm.featureFlags.pendingValues[key] = 'inherit'}}})],1)])]}}],null,true)}),_c('v-divider'),_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',_vm._l((_vm.deprecatedFeatureFlags),function(feature,index){return _c('v-col',{key:("deprecated_feature_flag_" + index),attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 4}},[_c('v-checkbox',{attrs:{"color":"secondary","disabled":_vm.isDisabled,"hide-details":"","label":feature.label},model:{value:(_vm.item[feature.key]),callback:function ($$v) {_vm.$set(_vm.item, feature.key, $$v)},expression:"item[feature.key]"}})],1)}),1)],1)]],2)],1),_c('ValidationProvider',{attrs:{"name":"recommended user photo size message","rules":"max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"disabled":_vm.isDisabled,"error-messages":errors,"label":"Recommended User Photo Size Message"},model:{value:(_vm.item.recommended_user_photo_size_message),callback:function ($$v) {_vm.$set(_vm.item, "recommended_user_photo_size_message", $$v)},expression:"item.recommended_user_photo_size_message"}})]}}],null,true)})]:_vm._e()],2),(!_vm.isDisabled)?[_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","disabled":invalid,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.exists ? 'Update' : 'Create')+" ")]),_c('v-spacer'),(_vm.exists)?_c('v-btn',{attrs:{"color":"red darken-3","text":""},on:{"click":function($event){return _vm.openConfirmDeletionDialog()}}},[_vm._v(" Archive ")]):_vm._e()],1)]:_vm._e(),(_vm.isLoading)?_c('Loader'):_vm._e()],2)]}}],null,false,1397474683)}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogs.confirmDeletion.display),callback:function ($$v) {_vm.$set(_vm.dialogs.confirmDeletion, "display", $$v)},expression:"dialogs.confirmDeletion.display"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.destroy()}}},[_c('v-card',[_c('v-card-title',[_c('h5',{staticClass:"ma-0"},[_vm._v(" Confirm Archival ")])]),_c('v-card-text',[_vm._v(" Type \""+_vm._s(_vm.item.subdomain)+"\" to confirm archival. "),_c('v-text-field',{staticClass:"mt-4",attrs:{"hide-details":"","label":"Subdomain","outlined":""},model:{value:(_vm.dialogs.confirmDeletion.confirmation),callback:function ($$v) {_vm.$set(_vm.dialogs.confirmDeletion, "confirmation", $$v)},expression:"dialogs.confirmDeletion.confirmation"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-3","disabled":_vm.dialogs.confirmDeletion.confirmation !== _vm.item.subdomain,"text":"","type":"submit"}},[_vm._v(" Archive ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogs.confirmDeletion.display = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }