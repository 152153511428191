<template>
  <div
    class="file-selector"
  >
    <div
      class="text-center"
    >
      <v-btn
        class="ma-0"
        @click="$refs.fileInput.click()"
      >
        <v-icon
          class="mr-2"
        >
          file_upload
        </v-icon>
        {{ file !== null ? 'Change' : 'Choose' }}
      </v-btn>
      <input
        v-show="false"
        ref="fileInput"
        :accept="extensions.length ? extensions.map(e => `.${e}`).join(', ') : ''"
        class="off-screen"
        type="file"
        @change="handleFileChange($event)"
      >
      <small
        class="d-block mt-2"
      >
        <div
          v-if="extensions.length > 0"
          class="mt-1"
        >
          Accepted: {{ extensions.map(e => `.${e}`).join(', ') }}
        </div>
      </small>
    </div>
    <div
      v-if="file"
      class="mt-2"
    >
      <v-divider
        class="my-3"
      />
      <div
        class="elipsis-text-outer"
      >
        <div
          class="elipsis-text"
        >
          <v-icon
            class="mr-2"
          >
            insert_drive_file
          </v-icon>
          {{ file.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileSelector',

  props: {
    extensions: {
      default: () => ([]),
      type: Array,
    },
  },

  data() {
    return {
      file: null,
    };
  },

  methods: {
    /** @return {void} */
    handleFileChange(event) {
      this.file = event.target.files[0] || event.dataTransfer.files[0];
      this.$emit('change', this.file);
    },
  },
};
</script>

<style lang="scss">
.file-selector {
  background-color: #f3f3f3;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  padding: 10px;
}
</style>
