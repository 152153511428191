<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-toolbar-title
        class="mr-4"
      >
        <router-link
          class="white--text text-decoration-none"
          :to="{ name: 'home' }"
        >
          myTRIBUS
        </router-link>
      </v-toolbar-title>
      <v-btn
        class="mr-3"
        text
        :to="{ name: 'network.index' }"
        v-if="$isLoggedIn"
      >
        Networks
      </v-btn>
      <v-menu
        offset-y
      >
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            v-on="on"
            text
            v-if="$isLoggedIn"
          >
            Tools
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(tool, index) in tools"
            :key="index"
            :to="tool.to"
          >
            {{ tool.label }}
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer/>
      <v-btn
        @click="dialogs.confirmLogOut.display = true"
        text
        v-if="$isLoggedIn"
      >
        Log Out
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <v-dialog
      width="500"
      v-model="dialogs.confirmLogOut.display"
    >
      <v-card>
        <v-card-title>
          <h5>
            Log Out
          </h5>
        </v-card-title>
        <v-card-text>
          Are you sure that you want to log out?
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            @click="signOut()"
            color="red darken-3"
            text
            type="submit"
          >
            Log Out
          </v-btn>
          <v-btn
            @click="dialogs.confirmLogOut.display = false"
            text
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      dialogs: {
        confirmLogOut: {
          display: false,
        },
      },
    };
  },

  computed: {
    /** @type {array} */
    tools() {
      return [
        {
          label: 'Format Contact Import',
          to: {
            name: 'tools.formatContactImport',
          },
        },
      ];
    },
  },

  methods: {
    signOut() {
      this.$store.commit('setAuthToken', null);
      this.$router.push({ name: 'login' });
      this.dialogs.confirmLogOut.display = false;
    },
  },
};
</script>

<style lang="scss">
.required {

  label {

    &:after {
      content: '*';
      display: inline;
    }
  }
}
</style>
