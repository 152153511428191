var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pa-5",style:({ maxWidth: '1500px' }),attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-layout',[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('h5',[_vm._v(" Networks "),_c('small',[_vm._v(" "+_vm._s(_vm.total)+" ")])])]),_c('v-col',{staticClass:"py-0 text-right"},[_c('v-btn',{attrs:{"color":"secondary","to":{ name: 'network.create' }}},[_vm._v(" Create ")])],1)],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"hide-default-footer":"","items":_vm.items,"items-per-page":_vm.queryParams.per_page,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.uid",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getEditUrl(item)}},[_vm._v(" "+_vm._s(item.uid)+" ")])]}},{key:"item.subdomain",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("http://" + (item.subdomain) + "." + (item.customer_type.domain)),"rel":"noreferrer noopener","target":"_blank"}},[_vm._v(" "+_vm._s(item.subdomain)+" ")])]}},{key:"item.file_size_total",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formatFileSize(item.file_size_total))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.file_size_total)+" ")])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.created_at))+" ")]}},{key:"item.deleted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.deleted_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.updated_at))+" ")]}}])}),(_vm.total > _vm.queryParams.per_page)?[_c('v-divider'),_c('v-card-text',[_c('div',[_c('PageSelect',{attrs:{"page":_vm.queryParams.page,"per-page":_vm.queryParams.per_page,"total":_vm.total},on:{"input":function($event){return _vm.handleFilterInput('page', $event)}}})],1)])]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }